.portfolio {
    --background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.portfolio .portfolioFilters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
}

.portfolio .portfolioFilters li {
    cursor: pointer;
    display: inline-block;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    margin: 0 10px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.portfolio .portfolioFilters li:hover,
.portfolio .portfolioFilters li.filterActive {
    color: var(--accent-color);
}

.portfolio .portfolioFilters li:first-child {
    margin-left: 0;
}

.portfolio .portfolioFilters li:last-child {
    margin-right: 0;
}

@media (max-width: 575px) {
    .portfolio .portfolioFilters li {
        font-size: 14px;
        margin: 0 5px;
    }
}

.item {
    margin-top: 50px;

}

.portfolio .portfolioContent {
    position: relative;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);

}



.portfolio .portfolioContent img {
    transition: 0.3s;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.portfolio .portfolioContent .portfolioInfo {
    opacity: 0;
    position: absolute;
    inset: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: rgba(0, 0, 0, 0.7);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.portfolio .portfolioContent h4 {
    font-size: 16px;
    padding: 12px 10px;
    font-weight: 400;
    color: #ffffff;
    display: inline-block;
    background-color: var(--header-background-color);
    top: -30px;
    left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
    font-weight: bold;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -2px;
}

.portfolio .portfolioContent .portfolioInfo p {
    text-align: center;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    text-shadow: 4px 10px 13px rgba(0, 0, 0, 1)
}

.portfolio .portfolioContent .portfolioInfo .previewLink,
.portfolio .portfolioContent .portfolioInfo .detailsLink {
    position: absolute;
    left: calc(50% - 40px);
    font-size: 26px;
    top: calc(50% - 14px);
    color: #fff;
    transition: 0.3s;
    line-height: 1.2;
}

.portfolio .portfolioContent .portfolioInfo .previewLink:hover,
.portfolio .portfolioContent .portfolioInfo .detailsLink:hover {
    color: var(--accent-color);
}

.portfolio .portfolioContent .portfolioInfo .detailsLink {
    left: 50%;
    font-size: 34px;
    line-height: 0;
}

.portfolio .portfolioContent:hover .portfolioInfo {
    opacity: 1;
}
.stats .statsItem {
    padding: 30px;
    width: 100%;
}

.stats .statsItem i {
    color: var(--accent-color);
    display: block;
    font-size: 44px;
    float: left;
    line-height: 0;
}

.stats .statsItem .purecounter {
    color: var(--heading-color);
    font-size: 48px;
    line-height: 40px;
    display: block;
    font-weight: 700;
    margin-left: 10px;
}



.item {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.stats .statsItem p {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    padding: 15px 0 0 0;
    margin: 0;
    font-family: var(--heading-font);
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.ex_txt_1 {
    position: absolute;
    left: 80%;
    white-space: nowrap;
}

.ex_txt_2 {
    position: absolute;
    left: 93%;
    white-space: nowrap;
    font-size: 20px !important;
    top: -5px;
}

@media (max-width: 768px) {

    .stats .statsItem i {
        font-size: 34px;
    }

    .stats .statsItem .purecounter {
        font-size: 38px;
        line-height: 30px;
    }

    .item {
        width: 50%;
    }

    .item span {
        font-size: 12px;
    }

    .stats .statsItem {
        padding: 10px;
    }

    .stats .statsItem p {
        padding-top: 10px;
    }

    .ex_txt_1 {
        position: absolute;
        left: 75%;
        white-space: nowrap;
    }

    .ex_txt_2 {
        left: 82%;
        font-size: 14px !important;
        top: -5px;
    }
}
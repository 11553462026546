.services .serviceItem {
    position: relative;
}

.services .serviceItem .icon {
    background: var(--accent-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    transition: 0.5s;
    border: 1px solid var(--accent-color);
    margin-right: 20px;
}

.services .serviceItem .icon i {
    color: var(--contrast-color);
    font-size: 24px;
    line-height: 0;
}

.services .serviceItem:hover .icon {
    background: var(--contrast-color);
}

.services .serviceItem:hover .icon i {
    color: var(--accent-color);
}

.services .serviceItem .title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
}

.services .serviceItem .title a {
    color: var(--heading-color);
}

.services .serviceItem .title a:hover {
    color: var(--accent-color);
}

.services .serviceItem .description {
    line-height: 24px;
    font-size: 14px;
}
/* Header.module.css */
.header {
    --background-color: var(--dashboard-header-background-color);
    --default-color: var(--header-color);
    --heading-color: var(--header-color);
    color: var(--default-color);
    background-color: var(--background-color);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 0px;
    width: 280px;
    transition: all ease-in-out 0.3s;
    overflow-y: auto;
    z-index: 99997;
    border-right: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.profileImg img {
    margin: 15px auto;
    display: block;
    width: 120px;
    border: 8px solid color-mix(in srgb, var(--default-color), transparent 85%);
}

.logo {
    line-height: 1;
    margin-bottom: 15px;
}

.logo img {
    max-height: 32px;
    margin-right: 8px;
}

.logo h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
}

.socialLinks {
    margin: 0 0 20px 0;
}

.socialLinks a {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: color-mix(in srgb, var(--default-color), transparent 90%);
    color: var(--default-color);
    margin: 0 2px;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    transition: 0.3s;
}

.socialLinks a:hover {
    color: var(--contrast-color);
    background: var(--accent-color);
}

@media (min-width: 1200px) {

    .header~main,
    .header~#footer {
        margin-left: 300px;
    }
}

@media (max-width: 1199px) {
    .header {
        left: -100%;
    }
}

.headerShow {
    left: 0;
}

.headerToggle {
    color: var(--contrast-color);
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    position: fixed;
    top: 5px;
    right: 15px;
    z-index: 99999999;
    transition: background-color 0.3s;
}

.headerToggle:hover {
    color: var(--contrast-color);
}

.mobile-nav-toggle {
    display: none;
}

.navmenu {
    padding: 0;
    z-index: 9997;
    padding-top: 60px;
}

.navmenu ul {
    list-style: none;
    padding: 0 0 20px 0;
    margin: 0;
}

.navmenu a,
.navmenu a:focus {
    color: var(--nav-color);
    padding: 15px 30px;
    font-family: var(--nav-font);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: 0.3s;
    width: 100%;
}

.navmenu a .navicon,
.navmenu a:focus .navicon {
    font-size: 20px;
    margin-right: 10px;
}

.navmenu a .toggleDropdown,
.navmenu a:focus .toggleDropdown {
    font-size: 12px;
    line-height: 0;
    margin-left: auto;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    flex-shrink: 0;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
}

.navmenu a .toggleDropdown:hover,
.navmenu a:focus .toggleDropdown:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
}

.navmenu a:hover,
.navmenu .active,
.navmenu .active:focus {
    color: var(--nav-hover-color);
}

.navmenu .active {
    background-color: #0e0e0e;
}

.navmenu:hove {
    background-color: #0e0e0e !important;
}

.navmenu a:hover .navicon,
.navmenu .active .navicon,
.navmenu .active:focus .navicon {
    color: var(--accent-color);
}

.navmenu .active .toggleDropdown,
.navmenu .active:focus .toggleDropdown {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
}

.navmenu .dropdown {
    display: block;
}

.navmenu .dropdown a,
.navmenu .dropdown a:focus {
    color: --nav-dropdown-color;
}

.navmenu .dropdown a:hover,
.navmenu .dropdown .active,
.navmenu .dropdown .active:focus {
    color: var(--nav-dropdown-hover-color);
}

.navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 5px 10px;
    margin: 5px 10px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
}

.navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
}

.navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
}

.navbar {
    position: fixed;
    z-index: 9999999;
    width: 100%;
    top: 0;
    height: 60px !important;
    background-color: var(--dashboard-navbar-background-color) !important;
    color: var(--header-color) !important;
}

@media (max-width: 768px) {
    .navbar {
        height: 55px !important;
    }

    .navmenu {
        padding-top: 55px;
    }

    .navmenu a,
    .navmenu a:focus {
        padding: 10px 30px;
        font-size: 16px;
    }

    .navText {
        font-size: 16px !important;
        padding: 0 !important;
        line-height: 20px !important;
    }

    .navText span {
        font-size: 12px;
    }
}

.navText {
    color: var(--header-color) !important;
}

.navDropDown {
    margin-left: auto;
    margin-right: 5%;
}

@media (max-width: 768px) {
    .navDropDown {
        display: none;
    }
}
.contact {
    --background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.contact .infoWrap {
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 30px;
}

@media (max-width: 575px) {
    .contact .infoWrap {
        padding: 20px;
    }
}

.contact .infoItem {
    margin-bottom: 40px;
}

.contact .infoItem_last {
    margin-bottom: 10px !important;
}

.contact .infoItem i {
    font-size: 20px;
    color: var(--accent-color);
    background: color-mix(in srgb, var(--accent-color), transparent 92%);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.contact .infoItem h3 {
    font-size: 20px;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.contact .infoItem p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
}

.contact .infoItem:hover i {
    background: var(--accent-color);
    color: var(--contrast-color);
}

.contact .phpEmailForm {
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
}

@media (max-width: 575px) {
    .contact .phpEmailForm {
        padding: 20px;
    }
}

.contact .phpEmailForm .errorMessage {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .phpEmailForm .sentMessage {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .phpEmailForm .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.contact .phpEmailForm .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
}

.contact .phpEmailForm input[type=text],
.contact .phpEmailForm input[type=email],
.contact .phpEmailForm textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .phpEmailForm input[type=text]:focus,
.contact .phpEmailForm input[type=email]:focus,
.contact .phpEmailForm textarea:focus {
    border-color: var(--accent-color);
}

.contact .phpEmailForm input[type=text]::placeholder,
.contact .phpEmailForm input[type=email]::placeholder,
.contact .phpEmailForm textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .phpEmailForm button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .phpEmailForm button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 25%);
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.socialLinks {
    margin: 20px 0 20px 0;
}



.socialLinks a {
    font-size: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: color-mix(in srgb, var(--default-color), transparent 90%);
    color: var(--default-color);
    margin: 0 10px;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    transition: 0.3s;
}

@media (max-width: 768px) {
    .socialLinks a {
        font-size: 20px;
        margin: 0 10px;
        width: 40px;
        height: 40px;
    }
}

.socialLinks a:hover {
    color: var(--contrast-color);
    background: var(--accent-color);
    transform: scale(1.1);
}
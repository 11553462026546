.hero {
    --default-color: #ffffff;
    --background-color: #00071C;
    --heading-color: #ffffff;
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero::before {
    position: absolute;
    height: 180%;
    width: 88%;
    left: -40%;
    top: 10%;
    background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%) !important;
    content: "" !important;
    animation: bottomEffectAnimation 7s infinite;
    overflow-x: hidden;
    z-index: 1;
}


@keyframes bottomEffectAnimation {
    0% {
        background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%);
        opacity: 1;
        left: -40%;
        top: 10%;
    }

    50% {
        background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%);
        opacity: 0.8;
        left: -35%;
        top: 30%;
    }

    100% {
        background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%);
        opacity: 1;
        left: -40%;
        top: 10%
    }
}

.hero::after {
    position: absolute;
    height: 180%;
    width: 88%;
    right: -60%;
    bottom: 10%;
    background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%) !important;
    content: "" !important;
    animation: topEffectAnimation 7s infinite;
    overflow-x: hidden;
}

@keyframes topEffectAnimation {
    0% {
        background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%);
        opacity: 1;
        right: -60%;
        bottom: 10%;
    }

    50% {
        background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%);
        opacity: 0.8;
        right: -40%;
        bottom: 20%;
    }

    100% {
        background: radial-gradient(circle, rgba(2, 0, 107, 0.8) 4%, rgba(45, 45, 61, 0) 59%);
        opacity: 1;
        right: -60%;
        bottom: 10%;
    }
}

.bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lottie {
    width: calc(100vw - 280px);
    opacity: 0.4;
    padding-top: 12%;
    transform: rotateY(0deg);
}

.hero:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 70%);
    position: absolute;
    inset: 0;
    z-index: 2;
}

.content {
    position: absolute;
    z-index: 3;
    filter: drop-shadow(0px 5px 10px #000);
    top: 15%;
}

.data {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    filter: drop-shadow(4px 4px 6px #383838);
}

.hero h2 {
    margin: 0;
    font-size: 74px;
    letter-spacing: 5px;
    font-weight: 700;
}

.hero p {
    margin: 5px 0 0 0;
    font-size: 30px;
    letter-spacing: 2px;
    opacity: 1;
    transition: margin 1s ease-in;
}

.hero p span {
    letter-spacing: 1px;
    border-bottom: 2px solid var(--accent-color);
}



.intro {
    position: absolute;
    top: 25%;
    z-index: 9999;
}

.all_together {
    margin: 10px 15% 0 15% !important;
    font-size: 20px !important;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 300 !important;
    margin-top: 20px;
    line-height: 35px;
}

.all_together span {
    letter-spacing: 1px;
    /* font-weight: 600 !important; */
    border-bottom: 0 !important;
    white-space: nowrap;
}



.socialLinks {
    margin: 20px 0 20px 0;
    position: absolute;
    top: 35%;
    z-index: 999999;
}

.socialLinks a {
    font-size: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: color-mix(in srgb, var(--default-color), transparent 90%);
    color: var(--default-color);
    margin: 0 10px;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    transition: 0.3s;
}

.socialLinks a:hover {
    color: var(--contrast-color);
    background: var(--accent-color);
    transform: scale(1.1);
}




@media (max-width: 768px) {

    .content {
        top: 30%;
    }

    .data {
        padding: 0 20px;
    }

    .hero h2 {
        font-size: 11vw;
    }

    .hero p {
        font-size: 5vw;
    }

    .lottie {
        width: 120vh;
        transform: rotate(-90deg);
        opacity: 0.2;
        left: -80px;
        top: 80px;
        position: relative;

    }

    .hero::before {
        height: 88%;
        width: 117%;
        left: -75%;
        top: 37%;
        animation: bottomEffectAnimation 7s infinite;
        overflow-x: hidden;
    }

    @keyframes bottomEffectAnimation {
        0% {
            opacity: 1;
            left: -75%;
            top: 37%;
        }

        50% {
            opacity: 0.8;
            left: -65%;
            top: 27%;
        }

        100% {
            opacity: 1;
            left: -75%;
            top: 37%;
        }
    }

    .hero::after {
        display: none;
    }

    .intro {
        top: 36%;
    }

    .all_together {
        margin: 20px 10% 0 10% !important;
        font-size: 16px !important;
        letter-spacing: 2px;
        font-weight: 300 !important;
        line-height: 28px;
    }

    .all_together span {
        white-space: wrap;
    }

    .socialLinks {
        margin-top: 50px;
    }

    .socialLinks a {
        font-size: 20px;
        margin: 0 6px;
        width: 40px;
        height: 40px;
    }
}

.readyToHide {
    opacity: 0 !important;
    transition: all 0.8s ease-in;
}

.scrollDown {
    position: absolute;
    bottom: 10%;
    z-index: 999;
    font-size: 14px !important;
    cursor: pointer;
}

.scroll_down_animation {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
    bottom: 2%;
}

@media (max-width: 768px) {
    .scroll_down_animation {
        width: 100px;
        bottom: 10%;
    }

    .socialLinks {
        top: 52%;
    }
}

.downloadResume {
    background: color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 12px 30px;
    position: relative;
    z-index: 999999;
    border-radius: 5px;
    transition: 0.3s;
    color: var(--contrast-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloadResume p {
    font-size: 1.2rem;
    margin-bottom: 3px;
    margin-left: 10px;
}

.downloadDocAnimation {
    width: 50px;
    height: 50px;
    margin: -12px -10px !important;
}

.downloadResume:hover {
    color: var(--contrast-color);
    background-color: var(--accent-color);
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .downloadResume {
        margin-top: 85%;
    }
}
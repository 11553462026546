.skills {
    --background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.padding_less {
    padding-bottom: 40px !important;
}

.skills ul {
    list-style: none;
    padding: 0;
}

.skills ul li {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
}

.skills ul strong {
    margin-right: 10px;
}

.skills ul i {
    font-size: 18px;
    margin-right: 5px;
    color: var(--accent-color);
    line-height: 0;
}

.skills ul strong {
    font-size: 18px;
}



.skills a {
    background-color: #fff8f8;
    border-radius: 5px;
    color: #212121;
    filter: drop-shadow(0 0 1px #9e9e9e);
    font-size: 18px !important;
    font-weight: 500;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 5px 14px;
    white-space: nowrap;
}

.skills a span {
    text-transform: uppercase;

}

.skills a:hover {
    color: rgb(183, 0, 0);
    background-color: rgb(223, 223, 223);
}

.skills ul p {
    margin-left: 23px;
    font-size: 14px;
    margin-top: 5px;
}

.Skill_Container {
    width: 100%;
    display: flex;
}

.skill_name {
    white-space: nowrap;
    width: 204px;
}

.skill_items {
    width: calc(100% - 204px);
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

@media (max-width: 768px) {
    .Skill_Container {
        flex-wrap: wrap;
    }

    .skill_name {
        width: 100%;
    }

    .skills ul strong {
        font-size: 1rem;
    }


    .skill_items {
        width: calc(100% - 25px);
        margin-left: 25px;
        margin-top: 10px;
        gap: 10px;
    }

    .skills a {
        color: rgb(44, 78, 249);
        white-space: nowrap;
        background-color: rgb(241, 241, 241);
        padding: 4px 10px;
        border-radius: 5px;
        font-size: 14px !important;
        font-weight: 500;
        letter-spacing: 1px;
        filter: drop-shadow(0 0 1px #9e9e9e);
        font-weight: bold;
    }
}
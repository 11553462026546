.downloadResume {
    background: #fff;
    padding: 8px 20px;
    position: relative;
    z-index: 999999;
    border-radius: 5px;
    transition: 0.3s;
    color: var(--contrast-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    margin-top: 10px;
    border: 1px solid var(--heading-color);
}

.downloadResume p {
    font-size: 1rem;
    margin-bottom: 3px;
    margin-left: 10px;
    white-space: nowrap;
    color: var(--heading-color);
}

.downloadDocAnimation {
    width: 50px;
    height: 50px;
    margin: -12px -10px !important;
}

.downloadResume:hover p {
    color: blue;

}

.downloadResume:hover {
    border: 1px solid blue;
}

.resume .resumeTitle {
    color: var(--heading-color);
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
}

.resume .resumeItem {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid var(--accent-color);
    position: relative;
}

.resume .resumeItem h4 {
    line-height: 28px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .resume .resumeItem h4 {
        line-height: 24px;
        font-size: 16px;
    }
}

.resume .resumeItem h5 {
    font-size: 16px;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}


.resume .resumeItem ul {
    padding-left: 20px;
}

.resume .resumeItem em {
    font-size: 18px
}

@media (max-width: 768px) {
    .resume .resumeItem em {
        font-size: 16px
    }

}

.resume .resumeItem ul li {
    padding-bottom: 10px;
}

.resume .resumeItem:last-child {
    padding-bottom: 0;
}

.resume .resumeItem::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: var(--background-color);
    border: 2px solid var(--accent-color);
}